<template>
    <div class="wrap">
        <!-- pc布局 -->
        <b-container class="pc-shop-list">
            <el-row>
                <el-col :span="4">
                    <div class="label-tit"><span class="label"><router-link class="lias" :to="{name:'myself'}">{{$t('message.accountinfo')}}</router-link></span></div>
                    <div class="label-tit"><span class="label"><router-link class="lias" :to="{name:'myorder'}">{{$t('message.myorder')}}</router-link></span></div>
                    <div class="label-tit"><span class="label cur">{{$t('message.addressfrom')}}</span></div>
                </el-col>
                <el-col :span="20">
                    <div class="pc-shop-group-box">
                        <div class="address-from">
                            <div class="borlef"></div>
                            <div class="title">{{$t('message.shoaddress')}}</div>
                            <div class="pc-select">
                                <div class="pc-select-tit">{{$t('message.conuycity')}}</div>
                                <!--  v-model="addressParamsObj.country" -->
                                <select class="pc-select">
                                    <option value="Italia" selected>Italia</option>
                                </select>
                                <span class="el-icon-arrow-down"></span>
                            </div>
                            <div class="input input_odd"><input type="text" v-model="addressParamsObj.province" :placeholder="$t('message.province')" @blur="blurText"/></div>
                            <div class="input-two">
                                <input type="text" :placeholder="$t('message.name')" v-model="addressParamsObj.family_name"/>
                                <div class="t"></div>
                                <input type="text" :placeholder="$t('message.surname')" v-model="addressParamsObj.name"/>
                            </div>
                            <div class="input"><input type="text" v-model="addressParamsObj.info" :placeholder="$t('message.address')" /></div>
                            <div style="height:20px"></div>
                            <!-- <div class="input"><input type="text" v-model="addressParamsObj.apartment" :placeholder="$t('message.doornum')" /></div>
                            <div class="input input_odd"><input type="text" v-model="addressParamsObj.doorbell" :placeholder="$t('message.doorling')" /></div> -->
                            <div class="input-two">
                                <input type="text" :placeholder="$t('message.city')" v-model="addressParamsObj.city"/>
                                <div class="t"></div>
                                <input type="text" :placeholder="$t('message.postcode')" @blur="blurTextCode" v-model="addressParamsObj.postcode"/>
                            </div>
                            <div class="input"><input type="text" :placeholder="$t('message.telep')" v-model="addressParamsObj.mobile"  /></div>
                            <div class="link">
                                <b-row>
                                    <b-col class="col-ft"><span class="icon_checkbox" @click="addressParamsObj.is_default=!addressParamsObj.is_default" :class="{'checked':addressParamsObj.is_default==1}"></span>{{$t('message.defaultcheck')}}</b-col>
                                    <b-col>
                                        <span class="linkbtn" v-show="addflag" @click="getSubmitUpdata">{{$t('message.addsub')}}</span>
                                        <span class="linkbtn" v-show="!addflag" @click="getSubmit">{{$t('message.addsub')}}</span>
                                    </b-col>
                                </b-row>
                            </div>
                        </div>
                        <div class="tablelist" v-show="tableData.length>0">
                            <div class="thead">
                                <div class="th th_1"><div class="td">{{$t('message.destinatario')}}</div></div>
                                <div class="th th_2"><div class="td">{{$t('message.shoaddress')}}</div></div>
                                <div class="th th_3"><div class="td">{{$t('message.doorling')}}</div></div>
                                <div class="th th_4"><div class="td">{{$t('message.postalcode')}}</div></div>
                                <div class="th th_5"><div class="td">{{$t('message.telep')}}</div></div>
                                <div class="th th_6"><div class="td">{{$t('message.tbmore')}}</div></div>
                            </div>
                            <div class="tbody" v-for="(item,key) in tableData" :key="key">
                                <div class="th th_1"><div class="td">{{item.family_name}}{{item.name}}</div></div>
                                <div class="th th_2"><div class="td">{{item.info}}，{{item.city}}，{{item.province}}</div></div>
                                <!-- <div class="th th_2"><div class="td">{{item.info}}，{{item.apartment}}，{{item.city}}，{{item.province}}</div></div> -->
                                <!-- <div class="th th_3"><div class="td">{{item.doorbell}}</div></div> -->
                                <div class="th th_4"><div class="td">{{item.postcode}}</div></div>
                                <div class="th th_5"><div class="td">{{item.mobile}}</div></div>
                                <div class="th th_6">
                                    <div class="td">
                                        <span class="editicon" @click="getQueryItem(item)"></span>
                                        <span class="deleteicon" @click="getDeleteItem(item,key)"></span>
                                        <span class="curs" v-if="item.is_default" @click="getEditItem(item)">{{$t('message.defaultaddress')}}</span>
                                        <span class="nocurs" v-else @click="getEditItem(item)">{{$t('message.defaultcheck')}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="page" v-show="rows>size">
                            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="size" aria-controls="my-table" @change="handleCurrentChange"></b-pagination>    
                        </div>
                    </div>
                </el-col>
            </el-row>
        </b-container>
        <!-- wap布局 -->
        <b-container class="wap-navtab">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/users' }">{{$t('message.myuser')}}</el-breadcrumb-item>
                <el-breadcrumb-item>{{$t('message.addressfrom')}}</el-breadcrumb-item>
            </el-breadcrumb>
        </b-container>
        <b-container class="wap-shop-list">
            <div class="wap-shop-btn"><router-link class="lias" :to="{name:'myaddressadd',query:{type:1}}">{{$t('message.newaddress')}}</router-link></div>
            <div class="wap-address-group">
                <div class="wap-address-item" v-for="(item,index) in tableData" :key="index">
                    <div class="wap-address-txt pt"><span class="name">{{item.family_name}}{{item.name}}</span>{{item.mobile}}</div>
                    <div class="wap-address-txt">{{item.info}}，{{item.city}}，{{item.province}}</div>
                    <!-- <div class="wap-address-txt">{{item.info}}，{{item.apartment}}，{{item.city}}，{{item.province}}</div> -->
                    <div class="wap-address-txt">
                        <!-- <div class="wap-address-box">{{item.doorbell}}</div> -->
                        <div class="wap-address-box">{{item.postcode}}</div>
                    </div>
                    <div class="wap-address-txt">
                        <div class="wap-address-box">
                            <span class="cur" v-if="item.is_default" @click="getEditItem(item)">{{$t('message.defaultaddress')}}</span>
                            <span class="nocurs" v-else @click="getEditItem(item)">{{$t('message.defaultcheck')}}</span>
                        </div>
                        <div class="wap-address-btn">
                            <div class="wap-address-bom" @click="getEditInfo(item)">
                                <span class="edit"></span>{{$t('message.edits')}}
                                <!--  <router-link class="lias" :to="{name:'myaddressadd',query:{id:item.id,type:2}}"><span class="edit"></span>{{$t('message.edits')}}</router-link>-->
                            </div>
                            <div class="wap-address-bom" @click="getDeleteItem(item,index)"><span class="del"></span>{{$t('message.dels')}}</div>
                        </div>
                    </div>
                </div>
                <div class="page" v-show="currentPage!=totalPage" @click="getPage">{{$t('message.seemore')}}</div>
            </div>
        </b-container>
        <!-- 删除提示弹窗 -->
        <b-modal size="sm" centered v-model="dellaiog" hide-footer :title="$t('message.tiptitle')">
            <div class="d-block text-center">
                <p style="padding:30px 0;">{{$t('message.comfirdel')}}</p>
            </div>
            <b-button  block @click="toggleModal">{{$t('message.firm')}}</b-button>
        </b-modal>
    </div>
</template>
<script>
import ElementUI from 'element-ui';
import {jsReg,yooz_lang,setLocalItem,getLocalItem,delLocalItem} from '@/untils/common';
export default {
    name:'myaddress',
    inject:['reload'],
    components:{
    },
    data(){
        return{
            languageName:'',
            languageObj:{},
            token:'',
            currentPage:1,//当前页
            rows:0,//总条数
            size:10,//每页条数
            totalPage:0,//总页数
            addflag:false,
            finishedpie:false,
            addressParamsObj:{
                is_default:1,//1是选中，0是未选中
                country:'it',//（国家 添加时必填）、
                province:'',//省份
                city:'',//（城市 添加时必填）、
                info:'',//（详细地址 添加时必填）、
                family_name:'',//（姓 添加时必填）、
                name:'',//（名 添加时必填）
                mobile:'',//（手机号 添加时必填）、
                // apartment:'',//（公寓、门牌号 添加时必填）、
                // doorbell:'',//（门铃 添加时必填）
                postcode:'',//（邮编 添加时必填）、
            },

            tableData: [
                // {
                //     apartment: "1809"
                //     area: null
                //     city: "天津"
                //     country: "中国"
                //     create_time: "2022-06-14 18:03:51"
                //     default: 1
                //     delete_time: null
                //     doorbell: "1809"
                //     family_name: "丙"
                //     id: 20
                //     info: "南开区鑫茂科技园1号楼"
                //     is_default: 0
                //     mobile: "18810000000"
                //     name: "乙烯"
                //     postcode: "0001122"
                //     province: null
                //     update_time: "1970-01-01 08:00:00"
                //     user_id: 17
                //     user_name: null
                // }
            ],
            dellaiog:false,
            delitemobj:{},
            delobjindex:0,
        }
    },
    // watch:{
    //     searchParams:function() {
    //         this.getProvinceQueryPostcode();
    //     },
    // },
    // computed:{
    //     searchParams() {
    //         if(this.addressParamsObj.province){
    //             var params = {
    //                 province:this.addressParamsObj.province,
    //                 country:this.languageName
    //             };
    //             return params;
    //         }
    //     },
    // },
    methods: {
        // 设置为默认地址
        getEditItem(item){
            if(this.token&&item){
                let ischeck = 1;
                this.tableData.forEach((v,k)=>{
                    if(v.is_default==1){
                        if(item.is_default == v.is_default){
                            ischeck = 0;
                        }else{
                            // ischeck = 2;
                        }
                    }
                });
                // if(ischeck==2){
                //     return false;
                // }
                let params = {
                    token:this.token,//（用户token）、
                    id:item.id,//（数据id 修改时必填）、
                    country:item.country,//（国家 添加时必填）、
                    province:item.province,//省 物流创建请求 此数据必填
                    city:item.city,//（城市 添加时必填）、
                    info:item.info,//（详细地址 添加时必填）、
                    family_name:item.family_name,//（姓 添加时必填）、
                    name:item.name,//（名 添加时必填）
                    mobile:item.mobile,//（手机号 添加时必填）、
                    is_default:ischeck,//（是否为默认地址 1是 0否 默认0）、
                    // apartment:item.apartment,//（公寓、门牌号 添加时必填）、
                    // doorbell:item.doorbell,//（门铃 添加时必填）
                    postcode:item.postcode,//（邮编 添加时必填）、
                    is_add:0,//（是否添加 1是 0否 默认0 is_edit、is_add 二者其一必填）、
                    is_edit:1,//（是否编辑 1是 0否 默认0 is_edit、is_add 二者其一必填）
                }
                this.$http.api_user_fromaddresstoken(params).then((res)=>{
                    if(res){
                        if(res.code==1){
                            if(res.data){
                                this.tableData.forEach((v,k)=>{
                                    if(v.is_default==1){
                                        v.is_default = 0;
                                    }else{
                                        if(item.id == v.id){
                                            v.is_default = 1;
                                        }
                                    }
                                });
                                // this.reload();
                                // ElementUI.Message({
                                //     message:'设置默认成功',
                                //     type:'success',
                                //     center: true,
                                //     offset:10,
                                // });
                            }
                        }else{
                            ElementUI.Message({
                                message:res.message,
                                type:'error',
                                center: true,
                                offset:10,
                            });
                            return false;
                        }
                    }
                });
            }else{
                ElementUI.Message({
                    message:this.languageObj.logintip,
                    type:'error',
                    center: true,
                    offset:10,
                });
                return false;
            }
        },
        // 查看当前地址并编辑
        getQueryItem(item){
            if(this.token&&item){
                this.addressParamsObj = {
                    token:this.token,//（用户token）、
                    id:item.id,//（数据id 修改时必填）、
                    country:item.country,//（国家 添加时必填）、
                    province:item.province,//省 物流创建请求 此数据必填
                    city:item.city,//（城市 添加时必填）、
                    info:item.info,//（详细地址 添加时必填）、
                    family_name:item.family_name,//（姓 添加时必填）、
                    name:item.name,//（名 添加时必填）
                    mobile:item.mobile,//（手机号 添加时必填）、
                    is_default:item.is_default?1:0,//（是否为默认地址 1是 0否 默认0）、
                    // apartment:item.apartment,//（公寓、门牌号 添加时必填）、
                    // doorbell:item.doorbell,//（门铃 添加时必填）
                    postcode:item.postcode,//（邮编 添加时必填）、
                    is_add:0,//（是否添加 1是 0否 默认0 is_edit、is_add 二者其一必填）、
                    is_edit:1,//（是否编辑 1是 0否 默认0 is_edit、is_add 二者其一必填）
                };
                this.addflag = true;
                window.scrollTo(0,0);
            }else{
                ElementUI.Message({
                    message:this.languageObj.logintip,
                    type:'error',
                    center: true,
                    offset:10,
                });
                return false;
            }
        },
        // 单个删除地址
        getDeleteItem(item,index){
            if(this.token){
                this.delobj = item;
                this.delobjindex = index;
                this.dellaiog = !this.dellaiog;
            }else{
                ElementUI.Message({
                    message:this.languageObj.logintip,
                    type:'error',
                    center: true,
                    offset:10,
                });
                return false;
            }
        },
        hideModal() {
            this.dellaiog = !this.dellaiog;
        },
        toggleModal() {
            this.$http.api_user_addressdelete({
                token:this.token,//（用户token）、
                id:this.delobj.id,//（数据id）、
            }).then((res)=>{
                if(res){
                    if(res.code==1){
                        if(res.data){
                            this.tableData.forEach((v,k)=>{
                                if(this.delobjindex == k){
                                    this.tableData.splice(k,1);
                                }
                            });
                            this.dellaiog = false;
                            ElementUI.Message({
                                message:this.languageObj.delsuccess,
                                type:'success',
                                center: true,
                                offset:10,
                            });
                        }
                    }else if(res.code==-1){
                        // this.reload();
                        this.$store.commit('setLoginNameStatus', {});
                        delLocalItem('login_user_info');
                        this.$root.Bus.$emit('userInfoClick', {});
                        this.$store.commit('setLoginStatus',true);
                        this.$router.push({path:'/'});
                    }else{
                        ElementUI.Message({
                            message:this.languageObj.logintip,
                            type:'error',
                            center: true,
                            offset:10,
                        });
                        return false;
                    }
                }
            });
        },
        // 修改地址
        getSubmitUpdata(){
            if(this.token){
                if(this.addressParamsObj.country==''){
                    ElementUI.Message({
                        message:this.languageObj.areatip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }else if(this.addressParamsObj.province==''){
                    ElementUI.Message({
                        message:this.languageObj.provincetip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }else if(this.addressParamsObj.name==''){
                    ElementUI.Message({
                        message:this.languageObj.nametip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }else if(this.addressParamsObj.family_name==''){
                    ElementUI.Message({
                        message:this.languageObj.lasnametip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }else if(this.addressParamsObj.info==''){
                    ElementUI.Message({
                        message:this.languageObj.addresstip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }
                // else if(this.addressParamsObj.apartment==''){
                //     ElementUI.Message({
                //         message:this.languageObj.apartmenttip,
                //         type:'error',
                //         center: true,
                //         offset:10,
                //     });
                //     return false;
                // }
                // else if(this.addressParamsObj.doorbell==''){
                //     ElementUI.Message({
                //         message:this.languageObj.doortip,
                //         type:'error',
                //         center: true,
                //         offset:10,
                //     });
                //     return false;
                // }
                else if(this.addressParamsObj.city==''){
                    ElementUI.Message({
                        message:this.languageObj.citytip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }else if(this.addressParamsObj.postcode==''){
                    ElementUI.Message({
                        message:this.languageObj.postcodetip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }else if(this.addressParamsObj.mobile==''){
                    ElementUI.Message({
                        message:this.languageObj.telepstip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }else if(!jsReg.number.test(this.addressParamsObj.mobile)){
                    ElementUI.Message({
                        message:this.languageObj.phonerighttip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }else{
                    this.$http.api_user_fromaddresstoken(this.addressParamsObj).then((res)=>{
                        if(res){
                            if(res.code==1){
                                if(res.data){
                                    this.reload();
                                    ElementUI.Message({
                                        message:this.languageObj.updatetip,
                                        type:'success',
                                        center: true,
                                        offset:10,
                                    });
                                }
                            }else if(res.code==-1){
                                // this.reload();
                                this.$store.commit('setLoginNameStatus', {});
                                delLocalItem('login_user_info');
                                this.$root.Bus.$emit('userInfoClick', {});
                                this.$store.commit('setLoginStatus',true);
                                this.$router.push({path:'/'});
                            }else{
                                ElementUI.Message({
                                    message:res.message,
                                    type:'error',
                                    center: true,
                                    offset:10,
                                });
                                return false;
                            }
                        }
                    });
                }
            }else{
                ElementUI.Message({
                    message:this.languageObj.logintip,
                    type:'error',
                    center: true,
                    offset:10,
                });
                return false;
            }
        },
        // 新增地址
        getSubmit(){
            if(this.token){
                if(this.addressParamsObj.country==''){
                    ElementUI.Message({
                        message:this.languageObj.areatip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }else if(this.addressParamsObj.province==''){
                    ElementUI.Message({
                        message:this.languageObj.provincetip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }else if(this.addressParamsObj.name==''){
                    ElementUI.Message({
                        message:this.languageObj.nametip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }else if(this.addressParamsObj.family_name==''){
                    ElementUI.Message({
                        message:this.languageObj.lasnametip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }else if(this.addressParamsObj.info==''){
                    ElementUI.Message({
                        message:this.languageObj.addresstip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }
                // else if(this.addressParamsObj.apartment==''){
                //     ElementUI.Message({
                //         message:this.languageObj.apartmenttip,
                //         type:'error',
                //         center: true,
                //         offset:10,
                //     });
                //     return false;
                // }
                // else if(this.addressParamsObj.doorbell==''){
                //     ElementUI.Message({
                //         message:this.languageObj.doortip,
                //         type:'error',
                //         center: true,
                //         offset:10,
                //     });
                //     return false;
                // }
                else if(this.addressParamsObj.city==''){
                    ElementUI.Message({
                        message:this.languageObj.citytip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }else if(this.addressParamsObj.postcode==''){
                    ElementUI.Message({
                        message:this.languageObj.postcodetip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }else if(this.addressParamsObj.mobile==''){
                    ElementUI.Message({
                        message:this.languageObj.telepstip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }else if(!jsReg.number.test(this.addressParamsObj.mobile)){
                    ElementUI.Message({
                        message:this.languageObj.phonerighttip,
                        type:'error',
                        center: true,
                        offset:10,
                    });
                    return false;
                }else{
                    let params = {
                        token:this.token,//（用户token）、
                        // id:'',//（数据id 修改时必填）、
                        country:this.addressParamsObj.country,//（国家 添加时必填）、
                        province:this.addressParamsObj.province,//省 物流创建请求 此数据必填
                        city:this.addressParamsObj.city,//（城市 添加时必填）、
                        info:this.addressParamsObj.info,//（详细地址 添加时必填）、
                        family_name:this.addressParamsObj.family_name,//（姓 添加时必填）、
                        name:this.addressParamsObj.name,//（名 添加时必填）
                        mobile:this.addressParamsObj.mobile,//（手机号 添加时必填）、
                        is_default:this.addressParamsObj.is_default,//（是否为默认地址 1是 0否 默认0）、
                        // apartment:this.addressParamsObj.apartment,//（公寓、门牌号 添加时必填）、
                        // doorbell:this.addressParamsObj.doorbell,//（门铃 添加时必填）
                        postcode:this.addressParamsObj.postcode,//（邮编 添加时必填）、
                        is_add:1,//（是否添加 1是 0否 默认0 is_edit、is_add 二者其一必填）、
                        is_edit:0,//（是否编辑 1是 0否 默认0 is_edit、is_add 二者其一必填）
                    }
                    this.$http.api_user_fromaddresstoken(params).then((res)=>{
                        if(res){
                            if(res.code==1){
                                if(res.data){
                                    let arr = [params];
                                    this.tableData = arr.concat(this.tableData);
                                    this.addressParamsObj = {};
                                    this.reload();
                                }
                            }else if(res.code==-1){
                                // this.reload();
                                this.$store.commit('setLoginNameStatus', {});
                                delLocalItem('login_user_info');
                                this.$root.Bus.$emit('userInfoClick', {});
                                this.$store.commit('setLoginStatus',true);
                                this.$router.push({path:'/'});
                            }else{
                                ElementUI.Message({
                                    message:res.message,
                                    type:'error',
                                    center: true,
                                    offset:10,
                                });
                                return false;
                            }
                        }
                    });
                }
            }else{
                ElementUI.Message({
                    message:this.languageObj.logintip,
                    type:'error',
                    center: true,
                    offset:10,
                });
                return false;
            }
        },
        //获取地址列表
        getAddresslist(page){
            if(this.token){
                let params = {
                    token:this.token,
                    page:page,
                    size:this.size
                }
                this.$http.api_user_addresslist(params).then((res)=>{
                    if(res){
                        if(res.code==1){
                            if(res.data){
                                this.rows = res.data.count;
                                this.totalPage = res.data.totalpage;
                                this.tableData = res.data.list;
                            }
                        }else if(res.code==-1){
                            // this.reload();
                            this.$store.commit('setLoginNameStatus', {});
                            delLocalItem('login_user_info');
                            this.$root.Bus.$emit('userInfoClick', {});
                            this.$store.commit('setLoginStatus',true);
                            this.$router.push({path:'/'});
                        }else{
                            // ElementUI.Message({
                            //     message:res.message,
                            //     type:'error',
                            //     center: true,
                            //     offset:10,
                            // });
                            // return false;
                        }
                    }
                });
            }else{
                ElementUI.Message({
                    message:this.languageObj.logintip,
                    type:'error',
                    center: true,
                    offset:10,
                });
                return false;
            }
        },
        // 地址列表分页
        handleCurrentChange(page){
            this.getAddresslist(page);
        },
        // wap修改地址
        getEditInfo(item){
            if(item){
                setLocalItem("address_user_info",item);
                this.$router.push({path:'myaddressadd',query:{id:item.id,type:2}});
            }
        },
        // wap分页
        getPage(){
            if(this.currentPage<this.totalPage){
                this.currentPage = this.currentPage+1;
            }
            this.finishedpie = false;
            if(this.token){
                let params = {
                    token:this.token,
                    page:this.currentPage,
                    size:this.size
                }
                this.$http.api_user_addresslist(params).then((res)=>{
                    if(res){
                        if(res.code==1){
                            if(res.data){
                                this.rows = res.data.count;
                                this.totalPage = res.data.totalpage;
                                if(this.currentPage>1){
                                    this.tableData = this.tableData.concat(res.data.list);
                                }else{
                                    this.tableData = res.data.list;
                                }
                                // 只有一页
                                if(this.rows<this.size){
                                    this.finishedpie = true;
                                    return;
                                }
                                // 最后一页
                                if(this.currentPage == this.totalPage){
                                    this.finishedpie = true;
                                    return;
                                }
                            }
                        }else if(res.code==-1){
                            // this.reload();
                            this.$store.commit('setLoginNameStatus', {});
                            delLocalItem('login_user_info');
                            this.$root.Bus.$emit('userInfoClick', {});
                            this.$store.commit('setLoginStatus',true);
                            this.$router.push({path:'/'});
                        }else{
                            ElementUI.Message({
                                message:res.message,
                                type:'error',
                                center: true,
                                offset:10,
                            });
                            return false;
                        }
                    }
                })
            }else{
                ElementUI.Message({
                    message:this.languageObj.logintip,
                    type:'error',
                    center: true,
                    offset:10,
                });
                return false;
            }
        },
        blurText(){
            this.$http.api_datalist_getzipcodebyprovince({
                province:this.addressParamsObj.province,
                country:'it',//this.languageName
            }).then((res)=>{
                if(res){
                    this.addressParamsObj.postcode = '';
                    if(res.code==1){
                        if(res.data){
                            this.addressParamsObj.postcode = res.data.zip_code;
                        }
                    }else{
                        ElementUI.Message({
                            message:this.languageObj.province_codepost,
                            type:'error',
                            center: true,
                            offset:10,
                        });
                        return false;
                    }
                }
            });
        },
        blurTextCode(){
            this.$http.api_datalist_getprovincebyzipcode({
                zipcode:this.addressParamsObj.postcode,//邮编
                // country:this.languageName
            }).then((res)=>{
                if(res){
                    this.addressParamsObj.province = '';
                    if(res.code==1){
                        if(res.data&&res.data.length>0){
                            this.addressParamsObj.province = res.data[0].province;
                        }
                    }else{
                        ElementUI.Message({
                            message:this.languageObj.codepost_province,
                            type:'error',
                            center: true,
                            offset:10,
                        });
                        return false;
                    }
                }
            });
        },
    },
    created(){
        this.languageName = localStorage.lang?localStorage.lang:yooz_lang;
        if(this.languageName=='cn'){
            this.languageObj = {
                "logintip":"请先登录",
                "areatip":"请选择国家/地区",
                "provincetip":"请输入省份",
                "nametip":"请输入您的名",
                "lasnametip":"请输入您的姓",
                "addresstip":"请输入您的地址",
                // "apartmenttip":"请输入公寓、门牌号等",
                // "doortip":"请输入门铃",
                "citytip":"请输入您的城市",
                "postcodetip":"请输入邮编 ",
                "telepstip":"请输入您的电话",
                "delsuccess":"删除成功",
                "phonerighttip":"请输入正确的手机号",
                "updatetip":"修改地址成功",
                "province_codepost":"您输入的省份没有邮编",
                "codepost_province":"您输入的邮编没有省份",
            }
        }else if(this.languageName=='en'){
            this.languageObj = {
                "logintip":"please log in first",
                "areatip":"select a country",
                "provincetip":"enter province",
                "nametip":"enter your name",
                "lasnametip":"enter your last name",
                "addresstip":"enter your address",
                // "apartmenttip":"enter the apartment, house number, etc.",
                // "doortip":"enter the doorbell",
                "citytip":"enter your city",
                "postcodetip":"enter zip code",
                "telepstip":"enter your phone",
                "delsuccess":"Successfully deleted",
                "phonerighttip":"Please enter the correct mobile number",
                "updatetip":"Modify the address successfully",
                "province_codepost":"The province you entered does not have a zip code",
                "codepost_province":"The zip code you entered has no province",
            }
        }else if(this.languageName=='it'){
            this.languageObj = {
                "logintip":"fai prima il log in",
                "areatip":"seleziona un paese",
                "provincetip":"inserirsci la provincia",
                "nametip":"inserisci il tuo nome",
                "lasnametip":"inserisci il tuo cognome",
                "addresstip":"inserisci il tuo indirizzo",
                // "apartmenttip":"inserisci l'appartamento, il numero civico, ecc.",
                // "doortip":"inserisci il campanello",
                "citytip":"inserisci la tua città",
                "postcodetip":"inserisci il codice postale",
                "telepstip":"inserisci il tuo telefono",
                "delsuccess":"Eliminato con successo",
                "phonerighttip":"Inserisci il numero di cellulare corretto",
                "updatetip":"Modifica indirizzo andato a buon fine",
                "province_codepost":"La provincia inserita non ha un codice postale",
                "codepost_province":"Il codice postale che hai inserito non ha provincia",
            }
        }
        let token = getLocalItem('login_user_info')&&getLocalItem('login_user_info').token?getLocalItem('login_user_info').token:'';
        if(token){
            this.token = token;
            this.getAddresslist(1);//获取地址列表
        }else{
            this.$router.push({path:'/'});
            return false;
        }
    }
}
</script>
<style lang="scss" scoped>

// pc端分页样式优化
/deep/.page-item{
    .page-link:focus, 
    .page-link:active:focus, 
    .page-link.active:focus,
    .page-link.focus,
    .page-link:active.focus,
    .page-link.active.focus {
        outline: none;
        box-shadow:none;
    }
    &:nth-child(1){
        display: none;
        .page-link{
            color: transparent;
        }
        .page-link::before{
            content: '首页';
            padding-left:8px;
            color: #616166;
        }
    }
    &:nth-child(2){
        // .page-link{
        //     color: transparent;
        // }
        .page-link::before{
            // content: v-bind(langageprev);//'上一页';
            padding-left:5px;
            color: #616166;
        }
    }
    &:last-child{
        display: none;
        .page-link{
            color: transparent;
        }
        .page-link::before{
            content: '尾页';
            padding-left:8px;
            color: #616166;
        }
    }
    &:nth-last-child(2){
        // .page-link{
        //     color: transparent;
        // }
        .page-link::before{
            // content: v-bind(langagenext);//'下一页';
            padding-left:5px;
            color: #616166;
        }
    }
}
/deep/.page-item:first-child .page-link{
    border-radius: 0;
}
/deep/.page-item:last-child .page-link{
    border-radius: 0;
}
/deep/.page-link{
    border:1px solid #DDE0ED;
    margin: 0 4px;
    padding:6px 10px;
    
    color: #616166;
}
/deep/.page-item.active .page-link{
    color: #007bff;
    background-color: #fff;
    border-color: #fff;
    outline: none;
}
/deep/a{
    color:#616166 ;
    text-decoration: none;
}
.clear{
    clear: both;
}
.wap-navtab{
    padding:20px 10px 10px;
    
}
.pc-shop-list{
    padding:20px 10px 0;
    .label-tit{
        padding:20px 0 0;
        font-size:16px;
        .label{
            cursor: pointer;
            display: inline-block;
            
            color: #616166;
            margin: 0 10px;
            padding:0 10px 0 20px;
            vertical-align: middle;
        }
        .cur{
            color: #1F73B7;
            border-left:2px solid #1F73B7;
        }
    }
}
.pc-shop-group-box{
    padding:20px 10px 0;
    .address-from{
        padding:0 300px 40px 70px;
        position: relative;
        .borlef{
            height:300px;
            position: absolute;
            left: 0;
            top:0;
            border-left: 1px solid #eff2f9;
        }
        .title{
            font-size:18px;
            padding-bottom: 10px;
        }
        .select{
            width: 100%;
            margin-bottom: 20px;
            /deep/.el-select{
                width: 100%;
            }
        }
        .pc-select{
            width: 100%;
            height:58px;
            margin-bottom: 20px;
            padding:0 20px;
            border-radius:5px;
            border:1px solid #DDE0ED;
            position: relative;
            cursor: pointer;
            .pc-select{
                display: flex;
                height: auto;
                width: 100%;
                border: none;
                outline: none;
                padding: 0;
                margin: 0 0px;
                background: transparent;
                appearance:none;   
                -moz-appearance:none;   
                -webkit-appearance:none; 
            }
            .pc-one_select{
                height:58px;
                line-height:58px;
                width: 100%;
                border: none;
                outline: none;
                background: transparent;
                appearance:none;   
                -moz-appearance:none;   
                -webkit-appearance:none; 
            }
            .el-icon-arrow-down{
                position: absolute;
                right: 20px;
                top:20px;
            }
            .pc-select-tit{
                width: 100%;
                height:28px;
                line-height:28px;
                
                color: #95959E;
            }
            /deep/.el-select{
                width: 100%;
            }
        }
        .input_odd{
            margin-bottom: 20px;
        }
        .input-two{
            display: flex;
            -webkit-box-orient: horizontal;
            height: 58px;
            width: 100%;
            margin-bottom: 20px;
            .t{width:4%}
            input{
                height: 100%;
                width: 48%;
                border:1px solid #DDE0ED;
                outline: none;
                padding:0 20px;
                border-radius:5px;
                background: transparent;
                // margin: 0 2%;
            }
        }
        .input{
            height: 58px;
            width: 100%;
            border:1px solid #DDE0ED;
            margin-top: 20px;
            border-radius:5px;
            input{
                height: 100%;
                width: 100%;
                border: none;
                outline: none;
                padding:0 20px;
                background: transparent;
            }
        }
        .link{
            height: 40px;
            width: 100%;
            text-align: right;
            margin-top: 20px;
            .col-ft{
                text-align: left;
                .icon_checkbox{
                    float: left;
                    display: block;
                    width:20px;
                    height:20px;
                    background:url(~@/assets/images/icon_check.png) no-repeat left top;
                    background-size: 100% 100%;
                    margin-top:3px;
                    margin-right:8px;
                    cursor: pointer;
                    border-radius: 3px;
                }
                .checked{
                    background:url(~@/assets/images/icon_checked.png) no-repeat left top;
                    background-size: 100% 100%;
                }
            }
            .linkbtn{
                display: inline-block;
                padding:10px 20px;
                border-radius:4px;
                background: #222;
                color: #fff;
                
                cursor: pointer;
            }
        }
    }
    
    .page{
        display: flex;
        -webkit-box-orient: horizontal;
        justify-content: center;
        padding:20px 0;
    }
    .tablelist{
        margin-top: 20px;
        margin-bottom: 20px;
        width: 100%;
        .thead{
            width: 100%;
            display: flex;
            -webkit-box-orient: horizontal;
            .th{
                background: #F4F7FE;
                padding:10px 0;
                text-align: center;
                .td{
                    display: inline-block;
                    width: 100%;
                    color: #616166;
                    border-right:1px solid #DDE0ED;
                }
            }
            .th_1{
                width:10%;
            }
            .th_2{
                width:30%;
            }
            .th_3{
                width:10%;
            }
            .th_4{
                width:10%;
            }
            .th_5{
                width:20%;
            }
            .th_6{
                width:20%;
            }
        }
        .tbody{
            width: 100%;
            display: flex;
            -webkit-box-orient: horizontal;
            border-bottom: 1px solid #DDE0ED;
            .th{
                padding:10px 0;
                text-align: center;
                word-break:break-all;
                .td{
                    display: inline-block;
                    width: 100%;
                    .editicon{
                        display: inline-block;
                        width:20px;
                        height:20px;
                        background:url(~@/assets/images/icon_edit.png) no-repeat left top;
                        background-size: 100% 100%;
                        margin-right:8px;
                        vertical-align: middle;
                        cursor: pointer;
                    }
                    .deleteicon{
                        display: inline-block;
                        width:20px;
                        height:20px;
                        background:url(~@/assets/images/icon_del.png) no-repeat left top;
                        background-size: 100% 100%;
                        margin-right:8px;
                        vertical-align: middle;
                        cursor: pointer;
                    }
                    .curs{
                        cursor: pointer;
                        color: #1f73b7;
                    }
                    .nocurs{
                        cursor: pointer;
                        color: #2E2E2E;
                    }
                }
            }
            .th_1{
                width:10%;
            }
            .th_2{
                width:30%;
                text-align: left;
                padding:10px 20px;
            }
            .th_3{
                width:10%;
            }
            .th_4{
                width:10%;
            }
            .th_5{
                width:20%;
            }
            .th_6{
                width:20%;
            }
        }
        /deep/.el-table th.el-table__cell{
            background: #f4f7fe;
        }
        /deep/.el-table th.el-table__cell>.cell{
            border-left: 1px solid #dde0ed;
        }
    }
}
/deep/.el-breadcrumb__inner.is-link{
    color: #1f73b7;
    font-weight: normal;
}
/deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner, .el-breadcrumb__item:last-child .el-breadcrumb__inner a, .el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover, .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover{
    color: #95959e;
}

@media screen and (min-width: 1200px) {
    .wrap{
        .wap-shop-list{display: none;}
        .wap-navtab{display: none;}
    }
}
@media screen and(min-width: 960px) and (max-width: 1199px) {
    .wrap{
        .wap-shop-list{display: none;}
        .wap-navtab{display: none;}
    }
}
@media screen and(min-width: 768px) and (max-width: 959px) {
    .wrap{
        .wap-shop-list{display: none;}
        .wap-navtab{display: none;}
        .address-from{
            padding:0 100px 0 70px;
        }
    }
}
@media screen and(min-width: 480px) and (max-width: 767px) {
    .wrap{
        .pc-shop-list{display: none;}
        .wap-shop-list{
            padding:0 20px;
            .wap-shop-btn{
                border: 1px solid #222;
                padding:10px;
                text-align: center;
                width: 100%;
                margin:10px 0;
                border-radius: 4px;
                
                color: #2E2E2E;
                .lias{
                    display: block;
                    width: 100%;
                    
                    color: #2E2E2E;
                }
            }
            .wap-address-group{
                .wap-address-item{
                    width: 100%;
                    border-bottom: 1px solid #DDE0ED;
                    padding:10px 0;
                    .wap-address-txt{
                        display: flex;
                        -webkit-box-orient: horizontal;
                        width: 100%;
                        
                        // height:30px;
                        line-height:28px;
                            color: #616166;
                        .name{
                            display: inline-block;
                            font-size:16px;
                            margin-right: 20px;
                            color: #2E2E2E;
                        }
                        .wap-address-box{
                            width:50%;
                            
                            color: #616166;
                            
                            span{
                                
                                color: #2E2E2E;
                            }
                            .cur{
                                color: #1F73B7;
                            }
                        }
                        .wap-address-btn{
                            width:50%;
                            display: flex;
                            -webkit-box-orient: horizontal;
                            justify-content: right;
                            
                            color: #616166;
                            .wap-address-bom{
                                
                                margin-left: 10px;
                                span{
                                    display: inline-block;
                                    width:18px;
                                    height:18px;
                                    vertical-align: middle;
                                    margin-right: 5px;
                                    margin-top: -4px;
                                }
                                .del{
                                    background: url(~@/assets/images/icon_del.png) no-repeat left;
                                    background-size: 100% 100%;
                                }
                                .edit{
                                    background: url(~@/assets/images/icon_edit.png) no-repeat left;
                                    background-size: 100% 100%;
                                }
                            }
                        }
                    }
                    .pt{
                        color: #95959E;
                    }
                }
                .page{
                    padding:20px;
                    text-align: center;
                    color: #616166;
                }
            }
        }
    }
}
@media screen and (max-width: 479px) {
    .wrap{
        .pc-shop-list{display: none;}
        .wap-shop-list{
            padding:0 20px;
            margin:0;
            .wap-shop-btn{
                border: 1px solid #222;
                padding:10px;
                text-align: center;
                width: 100%;
                margin:10px 0;
                border-radius: 4px;
                
                color: #2E2E2E;
                .lias{
                    display: block;
                    width: 100%;
                    
                    color: #2E2E2E;
                }
            }
            .wap-address-group{
                .wap-address-item{
                    width: 100%;
                    border-bottom: 1px solid #DDE0ED;
                    padding:10px 0;
                    .wap-address-txt{
                        display: flex;
                        -webkit-box-orient: horizontal;
                        width: 100%;
                        
                        // height:30px;
                        line-height:28px;
                            color: #616166;
                        .name{
                            display: inline-block;
                            font-size:16px;
                            margin-right: 20px;
                            color: #2E2E2E;
                        }
                        .wap-address-box{
                            width:50%;
                            
                            color: #616166;
                            
                            span{
                                
                                color: #2E2E2E;
                            }
                            .cur{
                                color: #1F73B7;
                            }
                        }
                        .wap-address-btn{
                            width:50%;
                            display: flex;
                            -webkit-box-orient: horizontal;
                            justify-content: right;
                            
                            color: #616166;
                            .wap-address-bom{
                                
                                margin-left: 10px;
                                span{
                                    display: inline-block;
                                    width:18px;
                                    height:18px;
                                    vertical-align: middle;
                                    margin-right: 5px;
                                    margin-top: -4px;
                                }
                                .del{
                                    background: url(~@/assets/images/icon_del.png) no-repeat left;
                                    background-size: 100% 100%;
                                }
                                .edit{
                                    background: url(~@/assets/images/icon_edit.png) no-repeat left;
                                    background-size: 100% 100%;
                                }
                            }
                        }
                    }
                    .pt{
                        color: #95959E;
                    }
                }
                .page{
                    padding:20px;
                    text-align: center;
                    color: #616166;
                }
            }
        }
    }
    
}
</style>